import { useTranslation } from "react-i18next";

const Organizations = () => {
	const { t } = useTranslation();

	return (
		<>
			<section className="organizations">
				<h2>{t("HOMEPAGE.ABOUT.ORGANIZATIONS.TITLE")}</h2>
				<div className="logos-wrapper">
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/50x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />

					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />

					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
					<img className="logo" src="https://placehold.co/200x50/DCADDA/AAA" alt="placeholder" />
				</div>
			</section>
		</>
	);
};

export default Organizations;
