import GdprHeaderBlob from "components/blobs/GdprHeaderBlob";
import Footer from "components/footer/Footer";
import Navbar from "components/navbar/Navbar";
import { websiteUrl } from "consts/SEOConsts";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const GdprPage = () => {
	const { t } = useTranslation();
	const personalDataItems = t('GDPR.LIST_OF_PERSONAL_DATA.LIST', { returnObjects: true }) as string[];
	const researchItems = t('GDPR.PROCESSING.LIST_OF_RESEARCH_DATA', { returnObjects: true }) as string[];

	return (
		<>
			<Helmet>
				<title>GDPR - IntegrAGE - Self-Assessment for 55+ Workers+</title>
				<meta name="description" content="IntegrAGE - Discover your strengths with self-assessment tool for workers aged 55+. Evaluate your skills in technology, workplace integration and well-being." />
				<link rel="canonical" href={websiteUrl + "/gdpr"} />
			</Helmet>
			<Navbar />
			<main className="gdpr-page">
				<GdprHeaderBlob />
				<div className="content">
					<section className="section">
						<h2>{t("GDPR.WHO_WE_ARE.TITLE")}</h2>
						<p>{t("GDPR.WHO_WE_ARE.1")}</p>
						<p>{t("GDPR.WHO_WE_ARE.2")}</p>
						<p>{t("GDPR.WHO_WE_ARE.3")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.OFFICER.TITLE")}</h2>
						<p>{t("GDPR.OFFICER.1")}</p>
						<p>{t("GDPR.OFFICER.2")}</p>
						<p>{t("GDPR.OFFICER.3")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.LIST_OF_PERSONAL_DATA.TITLE")}</h2>
						<p>{t("GDPR.LIST_OF_PERSONAL_DATA.DESCRIPTION")}</p>
						<ul>
							{personalDataItems.map((item, index) => (
								<li key={index}>{item}</li>
							))}
						</ul>
					</section>
					<section className="section">
						<h2>{t("GDPR.PROCESSING.TITLE")}</h2>
						<p>{t("GDPR.PROCESSING.DESCRIPTION")}</p>
						<ul>
							<li>{t("GDPR.PROCESSING.1")}</li>
							<ul>
								<li>{t("GDPR.PROCESSING.2")}</li>
							</ul>
							<li>{t("GDPR.PROCESSING.3")}</li>
							<ul>
								<li>{t("GDPR.PROCESSING.4")}</li>
							</ul>
							<li>{t("GDPR.PROCESSING.5")}</li>
							<ul>
								{researchItems.map((item, index) => (
									<li key={index}>{item}</li>
								))}
							</ul>
						</ul>
					</section>
					<section className="section">
						<h2>{t("GDPR.LEGAL.TITLE")}</h2>
						<p>{t("GDPR.LEGAL.1")}</p>
						<p>{t("GDPR.LEGAL.2")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.TRANSFER.TITLE")}</h2>
						<p>{t("GDPR.TRANSFER.1")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.STORING.TITLE")}</h2>
						<p>{t("GDPR.STORING.1")}</p>
						<p>{t("GDPR.STORING.2")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.SECURITY.TITLE")}</h2>
						<p>{t("GDPR.SECURITY.1")}</p>
						<p>{t("GDPR.SECURITY.2")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.PASSWORD.TITLE")}</h2>
						<p>{t("GDPR.PASSWORD.1")}</p>
					</section>
					<section className="section">
						<h2>{t("GDPR.RIGHTS.TITLE")}</h2>
						<p>{t("GDPR.RIGHTS.1")}</p>
						<p>{t("GDPR.RIGHTS.2")}</p>
						<p>{t("GDPR.RIGHTS.3")}</p>
						<p>{t("GDPR.RIGHTS.4")}</p>
						<p>{t("GDPR.RIGHTS.5")}</p>
					</section>
				</div>
			</main>
			<Footer />
		</>
	);
};

export default GdprPage;
