import { useTranslation } from "react-i18next";

const GdprHeaderBlob = () => {
	const { t } = useTranslation();

	return (
		<section className="gdpr-header-blob">
			<div className="wrapper">
				<h1>{t("GDPR.TITLE")}</h1>
				<p>{t("GDPR.DESCRIPTION")}</p>
			</div>
		</section>
	);
};

export default GdprHeaderBlob;
